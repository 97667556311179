import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Main = styled.div`
  position: relative;
  max-width: 100rem;
  width: 85%;
  margin: 10rem auto 0;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;

  a:last-child {
    div {
      border-bottom: none;
    }
  }
`;

const SingleProjectWrap = styled.div`
  display: grid;

  .item-size {
    grid-area: size;
  }
  .item-name {
    grid-area: name;
  }
  .item-location {
    grid-area: location;
    justify-self: end;
  }
  .item-category {
    grid-area: category;
  }
  .item-year {
    grid-area: year;
    justify-self: end;
  }

  grid-template-columns: auto 1fr 10rem;
  grid-template-rows: auto;
  grid-template-areas:
    'category category year'
    'size name location';

  gap: 1.5rem;
  border-bottom: 1px solid var(--green-light);
  padding-bottom: 1.5rem;

  @media (min-width: 650px) {
    grid-template-columns: 7rem 1fr 12rem 12rem auto;
    grid-template-areas: 'size name location category year';
    gap: 1rem;

    .item-year {
      justify-self: start;
    }

    .item-location {
      justify-self: start;
    }
  }

  @media (min-width: 900px) {
    grid-template-columns: 7rem 1fr 18rem 18rem auto;
  }
`;

const TableHeader = styled.div`
  display: none;

  @media (min-width: 650px) {
    display: grid;
    grid-template-columns: 7rem 1fr 12rem 12rem auto;
    gap: 1rem;
  }

  @media (min-width: 900px) {
    grid-template-columns: 7rem 1fr 18rem 18rem auto;
  }
`;

function SingleProject({ project }) {
  return (
    <Link to={`/architecture/${project.slug.current}`}>
      <SingleProjectWrap>
        <span className="item-size">{project.size}</span>
        <span className="bold item-name">{project.name}</span>
        <span className="item-location">{project.location}</span>
        <span className="item-category">{project.category}</span>
        <span className="item-year">{project.year}</span>
      </SingleProjectWrap>
    </Link>
  );
}

const ProjectList = ({ projects }) => (
  <Main>
    <TableHeader>
      <span className="subtitle">scala</span>
      <span className="subtitle">progetti</span>
      <span className="subtitle">luogo</span>
      <span className="subtitle">categoria</span>
      <span className="subtitle">anno</span>
    </TableHeader>
    {projects.map((project) => (
      <SingleProject key={project.id} project={project} />
    ))}
  </Main>
);

export default ProjectList;

import { graphql } from 'gatsby';
import React from 'react';
import Header from '../styles/Header';
import ProjectList from '../components/ProjectList';
import SEO from '../components/SEO';

const ArchitecturePage = ({ data }) => {
  const architecture = data.architecture.nodes[0];
  const projects = data.projects.nodes.sort((a, b) => b.year - a.year);

  return (
    <>
      <SEO title="Architettura" />
      <Header>
        <h1>{architecture.name}</h1>
        <section>
          <p>{architecture.description}</p>
          <h3 className="subtitle">Strumenti</h3>
          <p>{architecture.skills}</p>
          <div>
            <a href="mailto:info@mabastudio.eu" className="orange">
              {architecture.subtitile}
            </a>
          </div>
        </section>
        <ul>
          {architecture.services.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
      </Header>
      <ProjectList projects={projects} />
    </>
  );
};

export default ArchitecturePage;

export const query = graphql`
  query ArchQuery {
    architecture: allSanityPage(filter: { name: { regex: "/arch/i" } }) {
      nodes {
        name
        subtitile
        description
        skills
        services
      }
    }

    projects: allSanityProject {
      nodes {
        year
        size
        name
        location
        description
        collaboration
        id
        category
        images {
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
